<template>
  <base-page
    :title="$t('adminAccount.title_platform')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.userAccountFind"
      :defaultParams="{
        scopeCompanyId: $store.getters.company.id,
        needParentUserAccountGroup: 'YES',
      }"
      :columns="columns"
      :multiple="true"
      v-model="tableRows"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
      <!-- 登录名 -->
      <template v-slot:accountId="{ row }">
        <template v-if="row.type === 'SEATS'">
          <span
            v-if="
              !row.nextSubscribeTime ||
              row.nextSubscribeTime - new Date().getTime() >
                30 * 24 * 60 * 60 * 1000
            "
            style="color: rgb(47, 221, 96)"
            >{{ row.accountId }} ({{ $t('adminAccount.normal') }})</span
          >
          <span
            v-else-if="new Date().getTime() > row.nextSubscribeTime"
            style="color: red"
            >{{ row.accountId }} ({{ $t('adminAccount.pastDue') }})</span
          >
          <span v-else style="color: orange"
            >{{ row.accountId }} ({{ $t('adminAccount.inteirm') }})</span
          >
        </template>
        <template v-else> {{ row.accountId }}</template>
      </template>
      <!-- 账号类型 -->
      <template v-slot:type="{ row }">
        {{ accountTypeObj[row.type] }}
      </template>
      <!-- 所属企业 -->
      <template v-slot:company="{ row }">
        {{ row.company && row.company.name }}
      </template>
      <!-- 所属部门 -->
      <template v-slot:accountGroup="{ row }">
        {{ $util.getTreeNames(row.accountGroup, 'name') }}
      </template>
      <!-- 可见范围 -->
      <template v-slot:scope="{ row }">
        {{ scopeEnumObj[row.scope] }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- 修改、删除、详情、禁用、启用、重置密码 -->
        <template v-if="currentCompanyId === row.company.id">
          <!-- lang:修改 -->
          <el-button
            v-if="$page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_EDIT')"
            type="text"
            @click="
              functionMethod(
                { method: 'edit' },
                row,
                'SCOPE_USER_ACCOUNT_MANAGE_EDIT'
              )
            "
          >
            {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_EDIT') }}
          </el-button>
          <!-- lang:删除 -->
          <el-button
            v-if="$page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_DELETE')"
            type="text"
            @click="
              functionMethod(
                { method: 'del' },
                row,
                'SCOPE_USER_ACCOUNT_MANAGE_DELETE'
              )
            "
          >
            {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_DELETE') }}
          </el-button>
          <!-- lang:禁用 -->
          <el-button
            v-if="
              row.enable === 'NORMAL' &&
              $page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_DISABLE')
            "
            type="text"
            @click="
              functionMethod(
                { method: 'disable' },
                row,
                'SCOPE_USER_ACCOUNT_MANAGE_DISABLE'
              )
            "
          >
            {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_DISABLE') }}
          </el-button>
          <!-- lang:启用 -->
          <el-button
            v-if="
              row.enable !== 'NORMAL' &&
              $page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_RECOVER')
            "
            type="text"
            @click="
              functionMethod(
                { method: 'recover' },
                row,
                'SCOPE_USER_ACCOUNT_MANAGE_RECOVER'
              )
            "
          >
            {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_RECOVER') }}
          </el-button>
          <!-- lang:重置密码 -->
          <el-button
            v-if="$page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_RESET_PASSWORD')"
            type="text"
            @click="
              functionMethod(
                { method: 'resetPassword' },
                row,
                'SCOPE_USER_ACCOUNT_MANAGE_RESET_PASSWORD'
              )
            "
          >
            {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_RESET_PASSWORD') }}
          </el-button>
        </template>
        <!-- lang:详情 -->
        <el-button
          v-if="$page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_VIEW')"
          type="text"
          @click="
            functionMethod(
              { method: 'view' },
              row,
              'SCOPE_USER_ACCOUNT_MANAGE_VIEW'
            )
          "
        >
          {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_VIEW') }}
        </el-button>
        <!-- lang:续订 -->
        <el-button
          v-if="
            row.type === 'SEATS' &&
            row.subscribeType === 'MANUAL' &&
            currentCompanyId === row.company.id &&
            $page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_RENEW')
          "
          type="text"
          @click="renew(row)"
        >
          {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_RENEW') }}
        </el-button>
        <!-- 轨迹图-->
        <el-button
          v-if="
            row.type === 'AR' &&
            $page.showBtn('SCOPE_USER_ACCOUNT_MANAGE_TRAJECTORIES')
          "
          type="text"
          @click="userAccountLocationFind(row)"
        >
          {{ $t('functionEnum.SCOPE_USER_ACCOUNT_MANAGE_TRAJECTORIES') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <password-detail
      v-if="showPasswordDetail"
      :tableRow="tableRow"
      @closePage="closePasswordPage"
    />
    <base-dialog
      class="ARTrackBox"
      v-if="showArTrack"
      width="1000px"
      :showConfirm="false"
      :title="$t('adminAccount.track')"
      @cancel="showArTrack = false"
    >
      <div id="ARTrack" style="width: 100%; height: 600px"></div>
    </base-dialog>
    <!-- 续订弹框 -->
    <dialog-form-list
      v-if="showRenew"
      :title="$t('adminAccount.renew')"
      :config="renewConfig"
      :formData="renewFormData"
      @cancel="showRenew = false"
      @confirm="renewConfirm"
    />
    <dialog-import
      ref="dialogImport"
      v-if="showDialogImport"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.userAccountImportFromExcel"
      :defaultParams="importParams"
      :defineDeal="true"
      :template="importTemplate"
      @cancel="showDialogImport = false"
      @confirm="importFormList"
    >
      <!-- 账户相关 -->
      <form-list
        style="padding-top: 20px"
        ref="importFormList"
        :formData="importFormData"
        :config="importConfig"
      />
    </dialog-import>
  </base-page>
</template>

<script>
import Detail from './detail'
import PasswordDetail from './passworDetail'
import { importFile } from '@/utils/ajax'

export default {
  components: {
    Detail,
    PasswordDetail,
  },
  data() {
    const self = this
    const lang = this.$util.getLocalStorage('lang')
    return {
      importTemplate: `/template/${lang}/account.xlsx`,
      currentCompanyId: this.$store.getters.company.id,
      // 搜索
      formData: {},
      config: [
        // lang:关键字
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('adminAccount.nameOrNotes'),
          },
        },
        // // lang:账号类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.userAccountType(),
        //   },
        // },
        // lang:所属部门
        {
          tag: 'el-cascader',
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          span: 6,
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // lang:可见范围
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.scope'),
        //   prop: 'scope',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.scopeEnum(),
        //   },
        // },
        // 创建时间
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 6,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      accountTypeObj: this.$util.listToObj(this.$dict.userAccountType()),
      scopeEnumObj: this.$util.listToObj(this.$dict.scopeEnum()),
      enableObj: this.$util.listToObj(this.$dict.enable()),
      subscribeTypeObj: this.$util.listToObj(this.$dict.subscribeType()),
      tableRow: null,
      tableRows: [],
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // // lang:所属企业
        // {
        //   label: this.$t('adminAccount.company'),
        //   prop: 'companyId',
        // },
        // lang:所属部门
        {
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
        },
        // lang:状态
        {
          label: this.$t('base.enable'),
          prop: 'enable',
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
        },
        // lang:账号类型
        // {
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        // },
        // lang:可见范围
        // {
        //   label: this.$t('adminAccount.scope'),
        //   prop: 'scope',
        // },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickName',
        },
        // // lang:有效期至
        // {
        //   label: this.$t('adminAccount.nextSubscribeTime'),
        //   prop: 'nextSubscribeTime',
        //   callback: (row) => {
        //     if (row.type !== 'SEATS' || !this.isShowScrible(row.company)) return
        //     return this.$util.format(row.nextSubscribeTime, 'yyyy-MM-dd hh:mm')
        //   },
        // },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '350px',
        },
      ],
      // 表单页
      showDetail: false,
      // 重置密码
      showPasswordDetail: false,
      // 轨迹图
      showArTrack: false,
      // 续订相关
      showRenew: false,
      renewFormData: {},
      renewConfig: [
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          tagProps: {
            disabled: true,
          },
        },
        {
          label: this.$t('adminAccount.renewNumber'),
          prop: 'renewNumber',
          rules: {
            noNull: true,
            type: 'positiveInt',
          },
        },
      ],
      // 导入
      showDialogImport: false,
      importParams: {},
      importFormData: {
        scope: 'CHANNEL_LINE',
      },
      importConfig: [
        // lang:账号类型
        {
          tag: 'el-select',
          label: this.$t('adminAccount.type'),
          prop: 'type',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.userAccountType(),
          },
          tagEvents: {
            change(type) {
              if (type === 'SEATS') {
                const { company } = self.$store.getters
                if (self.isShowScrible(company)) {
                  self.importConfig[1].hidden = false
                }
              } else {
                self.importConfig[1].hidden = true
              }
            },
          },
        },
        // lang:订阅模式
        {
          tag: 'el-select',
          label: this.$t('adminAccount.subscribeType'),
          prop: 'subscribeType',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.subscribeType(),
          },
        },
        // lang:可见范围
        {
          tag: 'el-select',
          label: this.$t('adminAccount.scope'),
          prop: 'scope',
          rules: {
            noNull: false,
          },
          tagProps: {
            options: this.$dict.scopeEnum(),
            disabled: true,
          },
        },
        // lang: 所属部门
        {
          tag: 'el-cascader',
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
      ],
    }
  },
  mounted() {
    this.userAccountGroupFindTree()
  },
  methods: {
    // 是否显示账户订阅
    isShowScrible(company) {
      let flag = false
      const { level } = company
      if (level === 'ONE') {
        flag = true
      } else if (level === 'TWO') {
        if (company.feeModeStr.indexOf('ACCOUNT_SUBSCRIPTIONS') >= 0) {
          flag = true
        }
      } else if (company.feeMode === 'ACCOUNT_SUBSCRIPTIONS') {
        flag = true
      }
      return flag
    },
    // 导入验证和导入成功回掉
    importFormList(isImported) {
      if (isImported) {
        this.showDialogImport = false
        this.search()
      } else {
        this.$refs.importFormList.$refs.formList.validate((valid) => {
          if (!valid) return
          const importParams = this.$util.copyData(this.importFormData)
          if (!importParams.subscribeType) importParams.subscribeType = 'AUTO'
          importParams.serviceProvide = 'YES'
          importParams.canAddOutsideUserAccount = 'NO'
          importParams.shouldRemoveOutsideUserAccount = 'NO'
          importParams.accountGroup = JSON.stringify({
            id: importParams.accountGroup.pop(),
          })
          this.importParams = importParams
          this.$nextTick(() => {
            this.$refs.dialogImport.import()
          })
        })
      }
    },
    // 查询轨迹
    userAccountLocationFind(row) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountLocationFind,
        data: {
          userAccountId: row.id,
        },
      }).then((data) => {
        data = data || []
        this.openArTrack(data)
      })
    },

    // 打开轨迹图
    openArTrack(data) {
      this.showArTrack = true
      this.$nextTick(() => {
        // 处理数据
        const dotPoints = []
        const multipleDotPoints = []
        const linePoints = []
        data.forEach((item, index) => {
          // 处理路径描述
          let prefix = ''
          if (!index) prefix = this.$t('base.mapStart')
          if (index === data.length - 1) prefix = this.$t('base.mapEnd')
          console.log(prefix)
          // 单点集合
          if (!index || index === data.length - 1) {
            dotPoints.push(
              new window.AMap.Marker({
                icon: new window.AMap.Icon({
                  // 图标尺寸
                  size: new window.AMap.Size(19, 33),
                  // 图标的取图地址
                  image: require('./../userAccount/img/marker.png'),
                  // // 图标所用图片大小
                  imageSize: new window.AMap.Size(19, 33),
                }),
                position: new window.AMap.LngLat(item.longitude, item.latitude),
                label: {
                  direction: 'top',
                  content:
                    '<div style="font-size: 20px;color: #0d69b9">' +
                    prefix +
                    '</div>',
                },
              })
            )
          } else {
            // 海量点集合
            multipleDotPoints.push({
              lnglat: [item.longitude, item.latitude],
              name: `name${item.id}`,
              id: item.id,
            })
          }
          // 线集合
          linePoints.push(new window.AMap.LngLat(item.longitude, item.latitude))
        })
        // 地图实例
        const mapOptions = { zoom: 10 }
        if (data.length) {
          mapOptions.center = [data[0].longitude, data[0].latitude]
        }
        const map = new window.AMap.Map('ARTrack', mapOptions)
        // 点实例
        const mass = new window.AMap.MassMarks(multipleDotPoints, {
          style: {
            url: require('./../userAccount/img/marker.png'),
            anchor: new window.AMap.Pixel(6, 6),
            size: new window.AMap.Size(19, 33),
          },
        })
        // 线实例
        const polyline = new window.AMap.Polyline({
          path: linePoints,
          strokeColor: '#0d69b9',
        })
        // 添加单点
        map.add(dotPoints)
        // 添加海量点
        mass.setMap(map)
        // 添加线
        map.add(polyline)
      })
    },
    // 确认续订
    renewConfirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountRenew,
        data: {
          id: this.tableList.id,
          renewNumber: this.renewFormData.renewNumber,
        },
      }).then(() => {
        // lang:删除成功或禁用成功或启用成功或重置成功
        this.$element.showMsg(this.$t('adminAccount.renewSuccess'), 'success')
        this.showRenew = false
        this.search()
      })
    },
    // 打开续订弹框
    renew(row) {
      this.$store.commit('updateCmd', 'renew')
      this.tableList = row
      this.renewFormData.accountId = row.accountId
      this.showRenew = true
    },
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          scopeCompanyId: this.$store.getters.company.id,
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
        this.importConfig[3].tagProps.options = data
      })
    },
    // 关闭重置密码详情回掉
    closePasswordPage() {
      this.showPasswordDetail = false
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.userAccountGroupId) {
        formData.userAccountGroupId = formData.userAccountGroupId.pop()
      }
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item, row, functionEnum) {
      if (row) this.tableRow = row
      const { method } = item
      this.$store.commit('updateCmd', method)
      if (functionEnum) {
        this.$store.commit(
          'updateDialogTitle',
          this.$t(`functionEnum.${functionEnum}`)
        )
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.optionsMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountDelete,
            this.$t('base.deleteConfirm'),
            this.$t('base.deleteSuccess')
          )
          break
        case 'disable':
          if (this.tableRow.enable === 'DISABLE') {
            this.$element.showMsg(this.$t('base.hasDisabled'))
            return
          }
          this.optionsMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountDisabled,
            this.$t('base.disableConfirm'),
            this.$t('base.disableSuccess')
          )
          break
        case 'recover':
          if (this.tableRow.enable === 'NORMAL') {
            this.$element.showMsg(this.$t('base.hasRecover'))
            return
          }
          this.optionsMethod(
            '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountRecover,
            this.$t('base.recoverConfirm'),
            this.$t('base.recoverSuccess')
          )
          break
        case 'resetPassword':
          this.showPasswordDetail = true
          break
        case 'export':
          this.multipleExport()
          break
        case 'import':
          for (let key in this.importFormData) {
            this.importFormData[key] = ''
          }
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    // 删除，禁用，启用
    optionsMethod(url, confirmTips, successTips) {
      this.$element
        .showConfirm({
          content: confirmTips,
        })
        .then(() => {
          this.$ajax({
            url: url,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功或禁用成功或启用成功或重置成功
            this.$element.showMsg(successTips, 'success')
            this.search()
          })
        })
    },
    // 多个导出
    multipleExport() {
      if (!this.tableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      const ids = this.tableRows.map((item) => item.accountId)
      this.exportMethod(ids)
    },
    // 导出
    exportMethod(ids) {
      const params = {
        qrcodeSize: 170,
        logoSize: 10,
        fontWidth: 12,
        clo: 3,
        pageCount: 3,
        accountIdSet: ids,
      }
      importFile({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountExportQrCode,
        // lang:平台用户账号
        name: this.$t('adminAccount.exportPlatfromUsers'),
        type: '.docx',
        data: params,
      })
    },
  },
}
</script>
