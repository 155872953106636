<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
    width="650px"
  >
    <template v-slot:authCode>
      <div style="position: relative">
          <el-input v-model="formData.authCode" :placeholder="$t('adminAccount.codeText')" maxlength="6" >
          <el-button slot="suffix" size="mini"  @click.stop="sendcode" v-if="codeShow">{{$t('adminAccount.getCode')}}<span id="count"></span>
          </el-button>
          <el-button slot="suffix" size="mini" disabled v-if="!codeShow">{{$t('adminAccount.sendCode')}}{{count}}s</el-button>
        </el-input>
      </div>
    </template>
    <!-- 验证码 -->
  </dialog-form-list>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    const self = this
    return {
      cmd: cmd,
      formData: {
        scope: 'CHANNEL_LINE',
        authCodeSeqNo:''
      },
      count: '', // 验证码倒计时
      timer:null ,
      codeShow: true,
      config: [
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
          rules: {
            // noNull: true,
            type: 'mobile',
          },
          tagProps: {
            maxLength: 11,
          },
          tagEvents:{
            change(data){
              // if (self.cmd !== 'add') return
              const reg = /^[1][3-9][0-9]{9}$/
              if (reg.test(data)) {
                // self.config[2].hidden = false
                if (self.cmd !== 'add') {
                  if(data === self.tableRow.mobile) {
                    self.config[2].hidden = true
                  }else {
                    self.config[2].hidden = false
                  }
                } else {
                  self.config[2].hidden = false
                }
              }else{
                self.config[2].hidden = true
              }
            },
          }
        },
        // 获取手机验证吗
        {
          tag: 'slot-content',
          label: this.$t('adminAccount.code'),
          prop: 'authCode',
          rules: {
            noNull: true,
          },
          hidden: true,
          tagProps: {
            placeholder: this.$t('base.validateCode'),
          },
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: cmd === 'add' ? false : true,
            maxLength: 32,
          },
        },
        // lang:密码
        {
          label: this.$t('adminAccount.password'),
          prop: 'password',
          hidden: cmd === 'add' ? false : true,
          rules: {
            noNull: true,
            type: 'password',
          },
          tagProps: {
            showPassword: true,
            placeholder: this.$t('base.validatePassword'),
          },
        },
        // // lang:账号类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        //   rules: {
        //     noNull: true,
        //   },
        //   tagProps: {
        //     options: this.$dict.userAccountType(),
        //   },
        //   tagEvents: {
        //     change(type) {
        //       if (type === 'SEATS') {
        //         const { company } = self.$store.getters
        //         const { level } = company
        //         if (level === 'ONE') {
        //           self.config[5].hidden = false
        //         } else if (level === 'TWO') {
        //           if (
        //             company.feeModeStr.indexOf('ACCOUNT_SUBSCRIPTIONS') >= 0
        //           ) {
        //             self.config[5].hidden = false
        //           }
        //         } else if (company.feeMode === 'ACCOUNT_SUBSCRIPTIONS') {
        //           self.config[5].hidden = false
        //         }
        //       } else {
        //         self.config[5].hidden = true
        //       }
        //     },
        //   },
        // },
        // lang:订阅模式
        {
          tag: 'el-select',
          label: this.$t('adminAccount.subscribeType'),
          prop: 'subscribeType',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.subscribeType(),
          },
        },
        // lang: 所属部门
        {
          tag: 'el-cascader',
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
        // lang:可见范围
        {
          tag: 'el-select',
          label: this.$t('adminAccount.scope'),
          prop: 'scope',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.scopeEnum(),
            disabled: true,
          },
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickName',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.userAccountGroupFindTree()
  },
  methods: {
    // 查询部门
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          scopeCompanyId: this.$store.getters.company.id,
          companyId: this.$store.getters.company.id,
        },
      }).then((data) => {
        this.config[6].tagProps.options = data
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          // 禁掉账号类型
          this.config[4].tagProps.disabled = true
          // 处理所属部门
          this.formData.accountGroup = this.$util.getTreeIds(
            this.tableRow.accountGroup
          )
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      delete formData.terminalPower
      // 处理密码
      if (this.cmd !== 'add') delete formData.password
      // 处理所属部门
      formData.accountGroup = {
        id: formData.accountGroup.pop(),
      }
      if (!formData.subscribeType) formData.subscribeType = 'AUTO'
      formData.serviceProvide = 'YES'
      formData.canAddOutsideUserAccount = 'NO'
      formData.shouldRemoveOutsideUserAccount = 'NO'
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log(formData)
      const { accountGroup } = formData
      formData.departmentId = accountGroup.id
      delete formData.accountGroup
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      }).catch((error) =>{
        this.$element.showMsg(this.$t('serverCode.' + error.msg), 'warning')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      formData.id = tableRow.id
      console.log(tableRow)
      formData.departmentId = tableRow.accountGroup.id
      delete formData.accountGroup
      // Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.userAccountUpdate,
        data: formData,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
    // 获取验证码
    sendcode() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sendCode,
        data:{
          mobile: this.formData.mobile,
        }
      }).then((data) => {
        this.formData.authCodeSeqNo = data
        console.log(data)
      })
      const TIME_COUNT = 30  //倒计时60秒
      if(!this.timer) {
          this.count = TIME_COUNT
          this.codeShow = false
          this.timer = setInterval(() => {
            if(this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.codeShow = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
       }
    }
  },
}
</script>
